<template>
  <section>
    <b-row class="match-height p-1">
      <b-col xl="8" md="6">
        <b-card no-body>
            <b-card-header
            class="pb-1">
            <b-card-title
              >Analisa Hasil TryOut</b-card-title>
            <b-nav>
              <b-nav-item>
                <router-link to="/user/list_hasil_tryout">
                  <b-button variant="primary">
                    <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    <span class="align-right"
                      ><b style="color: white"> Kembali</b></span
                    >
                  </b-button>
                </router-link>
              </b-nav-item>
            </b-nav>
          </b-card-header>
          
          <b-table-simple caption-top responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th class="text-center">Keterangan</b-th>
                <b-th class="text-center" style="width: 25%">Info</b-th>
                <b-th class="text-center" style="width: 10%">Score</b-th>
              </b-tr>
            </b-thead>
            <b-tbody
              v-for="outputAnalisa in dataAnalisa"
              v-bind:key="outputAnalisa.nama"
            >
              <b-tr>
                <b-td class="text-left">
                  {{ outputAnalisa.nama_study }}
                </b-td>
                <b-td class="text-center" variant="warning">
                  Benar {{ outputAnalisa.benar }} - {{ outputAnalisa.result }}
                </b-td>
                <b-td class="text-center" variant="success">
                  {{
                    Math.round(
                      (outputAnalisa.benar / outputAnalisa.result) * 100
                    )
                  }}</b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>

      <b-col xl="4" md="6">
        <b-card>
          <dl class="row mb-0">
            <dd class="col-sm-12">
              <h4><b class="text-primary text-center">Info TryOut</b></h4>
            </dd>
          </dl>
          <br />
          <b-card-body
            v-for="plan in dataPlan"
            v-bind:key="plan.id"
            class="p-0"
          >
            <dl class="row mb-0">
              <dt class="col-sm-4"><b>Ujian</b></dt>
              <dd class="col-sm-8">
                {{ plan.nama_event }}
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-4"><b>Soal</b></dt>
              <dd class="col-sm-8">{{ plan.jumlah_soal }} Soal</dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-4"><b>Durasi</b></dt>
              <dd class="col-sm-8">{{ plan.durasi }} Menit</dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-4"><b>Tanggal</b></dt>
              <dd class="col-sm-8">
                {{ formatDate(plan.tanggal) }}
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-4"><b>Waktu</b></dt>
              <dd class="col-sm-8">
                {{ plan.waktu }}
              </dd>
            </dl>
          </b-card-body>
          <p></p>
          <div v-for="skoring in dataResult" v-bind:key="skoring.id">
            <dl class="row mb-0">
              <dd class="col-sm-8">
                <h4>
                  <b class="text-primary text-center"
                    >Final Skor: {{ skoring.score }}</b
                  >
                </h4>
              </dd>
            </dl>
            <br />
            <dl class="row mb-0">
              <dd class="col-sm-12">
                <h4><b class="text-primary text-center">Grafik TryOut</b></h4>
              </dd>
            </dl>
            <vue-apex-charts
              type="pie"
              height="400"
              :options="radarChart.chartOptions"
              :series="[parseInt(skoring.correct), parseInt(skoring.wrong)]"              
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BTableSimple,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  BTable,
} from "bootstrap-vue";

import VueApexCharts from "vue-apexcharts";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    BCard,
    ToastificationContent,
    BImg,
    Swal,
    BCardText,
    BBadge,
    BCardHeader,
    BTableSimple,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
    BProgress,
    VueApexCharts,
    BTable,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id_session: localStorage.getItem("Uid"),
      id_param: this.$route.params.id,
      questionIndex: 0,
      currentQuestion: 0,
      currentAnswer: 0,
      radioGroup: 0,
      dataQuestion: [],
      datacountEmptyExam: 0,
      datacountAnswerExam: 0,
      datacountRaguExam: 0,
      dataviewAnswerExam: [],
      answer: "",
      start: 1,
      end: "",
      split: "",
      cons_answer: 0,
      isLoading: 0,
      answerIndex: 0,
      hours: "00",
      minutes: "00",
      seconds: "00",
      distance: 0,
      progressBar: 0,
      dataPlan: [],
      dataResult: [],
      dataAnalisa: [],
      items: [
        {
          keterangan: "Pemberian asuhan dan manajemen keperawatan",
          jumlah: "Benar 77 dari 27",
          Persentase: "50%",
        },
      ],
     

      radarChart: {
        series: [65, 35],
        chartOptions: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: ["Benar", "Salah"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          colors: ["#54d431", "#ed3b18"],
        },
      },
    };
  },

  async mounted() {
    this.checkAuth();
    await this.getDataMonitor();
    this.getPlan();
    this.getResult();
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async getQuestions() {
      await this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `questions/exam/` +
            this.id_param +
            `/` +
            this.id_session
        )
        .then((res) => {
          this.dataQuestion = res.data.data;
        });
    },
    async getDataMonitor() {
      await this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `questions/monitor_question_analisa/` +
            this.id_param +
            `/` +
            this.id_session
        )
        .then((res) => {
          this.dataAnalisa = res.data.data;
        });
    },
    getPlan() {
      this.$http
        // .get(process.env.VUE_APP_BACKEND_URL + "blogs/view")
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `registerplans/view3/` +
            this.id_session +
            `/` +
            this.id_param
        )
        .then((res) => {
          this.dataPlan = res.data.data;
        });
    },
    getResult() {
      this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `registerplans/view4/` +
            this.id_session +
            `/` +
            this.id_param
        )
        .then((res) => {
          this.dataResult = res.data.data;
        });
    },
    jumpquestion(number, answer, cons) {
      this.questionIndex = number;
      this.answerIndex = answer;
      this.cons_answer = cons;
      // console.log(this.questionIndex, this.answerIndex, this.cons_answer);
    },
    prev() {
      this.questionIndex--;
    },
    next() {
      this.questionIndex++;
    },
    formatDate(value) {
      moment.locale("id");
      if (value != null) {
        const val = moment(value).format("ll");
        return val;
      }
    },
  },
};
</script>

<style>
.space_good {
  white-space: pre-wrap;
}

.column_wrapper {
  column-count: 10;
}
</style>
